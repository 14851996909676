import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-casestudy',
  templateUrl: './casestudy.component.html',
  styleUrls: ['./casestudy.component.scss']
})
export class CasestudyComponent implements OnInit {

  public name = null;
  private title = 'Selected Green - Case study';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta
  ) { }
  
  ngOnInit(): void {
    
    this.titleService.setTitle(this.title);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.name = params['name'] ? params['name'] : null;
    });
  }

}
