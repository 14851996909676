import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Selected Green - Custom software development company';

  constructor(
    public router: Router,
    private metaTagService: Meta
  ) {
  }

  ngOnInit() {
    this.metaTagService.addTags([
      { name: 'keywords', content: 'custom software, development company' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ]);
  }

}
