import { Component, ViewEncapsulation} from '@angular/core';
import { environment } from './../../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent{
  public contents = environment.contents.footer;

  trackConversion(url: string): void {
    const callback = () => {
      if (typeof(url) !== 'undefined') {
        window.location.href = url;
      }
    };
    gtag('event', 'conversion', {
      'send_to': 'AW-970537564/TtG3CMfxy5QZENz05M4D',
      'event_callback': callback
    });
  }
  
}
