export const footer = {
    getInTouchTitle: 'Get in Touch',
    lookingProjectEstimate: '<p class="body18light">Looking for a project estimate or you want to <br/> get to know us better?</p>',
    scheduleACallButton: 'Schedule a call',
    address: '<p class="body16regular">Suite 1273, Fitzwilliam Business Centre, <br/>77 Sir John Rogerson\'s Quay, <br/>Dublin 2, Ireland</p>',
    phone: '+353 1 649 9020',
    email: 'hello@selectedgreen.com?subject=Business%20inquiry&body=Hello%20Selected%20Green!%0A%0AOur%20company%20name%20is%3A%0AWe%20want%20to%20integrate%3A%0A%0AWe%20have%20_%20months%20to%20deliver%20the%20final%20product.%0AOur%20budget%20is%20in%20range%20between%20x.000%20and%20xx.000EUR.%0ATech%20stack%20is%20based%20on%20(Microsoft%2C%20Amazon%2C%20Google)%0A%0AHear%20from%20you%20soon!%0A',
    emailVisible: 'hello@selectedgreen.com',
    copyRight: '<p>2024 © All right reserved. <br class="footer-brake"> Development by <a href="/">Selected Green</a></p>'
}
export const header = {
    getInTouchTitle:'<div class="title">Get in Touch</div>'
}

export const home = {
    block1Title: 'We build <br/> better products.',
    block1Text: '<p class="body18light">We are cloud native software development <br/> agency providing automations for optimizing <br/>your business processes.</p> <p class="body18semibold">Let\'s start transforming business together</p>',
    scheduleCall: 'Schedule a call',
    block2Title: 'What we do',
    block2SubTitle: 'Engineering solutions to elevate your process.',
    block2Row1Img: '<a href="/consultancy"><div class="frame rectangle-bl"><img alt="Consulting" src="/assets/img/Consulting_Big.svg" /></div></a>',
    block2Row1Text: '<a href="/consultancy"><h4 class="title body22semibold"><span>Consultancy & Discovery</span></h4><p class="body16regular">One of the most important parts of this process is getting to know you, your business and all its needs.  Solution building means clearly identifying and defining the issues that require solving in the first place. If that sounds complicated, don\'t worry, it\'s not. Part of our role is to help you navigate and define these barriers to efficiency.</p></a>',
    block2Row2Img: '<a href="/development"><div class="frame rectangle-br"><img alt="Coding" src="/assets/img/Coding_Big.svg" /></div></a>',
    block2Row2Text: '<a href="/development"><h4 class="title body22semibold"><span>Custom Software Development</span></h4><p class="body16regular">Now that we\'ve clearly identified the issues that require solving, it\'s time to get to work. We can implement relevant integrations, so you have all the necessary tools to work effectively and efficiently. We focus on technologies like AWS services (as we store everything on the cloud), Node.js, Python, Angular and Ionic framework.</p></a>',
    block2Row3Img: '<a href="/support"><div class="frame rectangle-tl"><img alt="Service" src="/assets/img/Service_Big.svg" /></div></a>',
    block2Row3Text:'<a href="/support"><h4 class="title body22semibold"><span>Post Delivery Maintenance & Support</span></h4><p class="body16regular">Keeping up with market trends means continued maintenance and improvements to the actions and integrations we implemented in the previous stage. We are dedicated to making your business more effective, efficient and successful.</p></a>',
    /* mobile start */
    block2MobRow1Title: 'Consultancy & <br> Discovery',
    block2MobRow1Text: '<p class="block-mob-text">One of the most important parts of this process is getting to know you, your business and all its needs.  Solution building means clearly identifying and defining the issues that require solving in the first place.<br><br> If that sounds complicated, don\'t worry, it\'s not. Part of our role is to help you navigate and define these barriers to efficiency.</p><a class="block-mob-link" href="/consultancy">See More</a>',
    block2MobRowIconClose:'<img src="/assets/img/Icon-arrow-down.svg" alt="">',
    block2MobRowIconOpen:'<img src="/assets/img/Icon-arrow-right.svg" alt="">',
    block2MobRow2Title: 'Custom Software Development',
    block2MobRow2Text:'<p class="block-mob-text">Now that we\'ve clearly identified the issues that require solving, it\'s time to get to work. We can implement relevant integrations, so you have all the necessary tools to work effectively and efficiently.<br><br> We focus on technologies like AWS services (as we store everything on the cloud), Node.js, Python, Angular and Ionic framework.</p><a class="block-mob-link" href="/development">See More</a>',
    block2MobRow3Title: 'Post Delivery Maintenance & Support',
    block2MobRow3Text: '<p class="block-mob-text">Keeping up with market trends means continued maintenance and improvements to the actions and integrations we implemented in the previous stage. We are dedicated to making your business more effective, efficient and successful.</p><a class="block-mob-link" href="/support">See More</a>',
    /* mobile end */
    block2ButtonText: 'Schedule a call',
    block3Title: 'Case studies',
    block3Subtitle: 'Check our case studies and success stories <br /> from our clients',
    block3PanelItemPills: ['Python', 'Django', 'mySQL', 'HTML', '+5 more'],
    block3PanelItemTitle: 'Icon Accounting Contractors Portal',
    block3PanelItemSubtitle: 'Icon Accounting is an accounting company from Dublin, Ireland. Check out full case study below showcasing different integrations that we did.',
    block3PanelItemImgUrl:'/assets/img/IconAccountingIllust.png',
    block3PanelItemPills1: ['Node.js', 'Angular', 'PostgreSQL', 'Amazon Cloud', '+5 more'],
    block3PanelItemTitle1: 'Rockwell',
    block3PanelItemSubtitle1: 'Rockwell Logic portal is a cutting-edge CRM platform tailored to meet the unique needs of financial planning and wealth management agencies.',
    block3PanelItemImgUrl1:'/assets/img/Mockup_1.svg',
    block3PanelItemPills2: ['Node.js', 'Angular', 'PostgreSQL', 'Amazon Cloud', '+5 more'],
    block3PanelItemTitle2: 'Borgo',
    block3PanelItemSubtitle2: 'Borgo Accounting has been facing the challenges of scattered client data, manual processes, and fragmented tools.',
    block3PanelItemImgUrl2:'/assets/img/Mockup_27.svg',
}

export const consultancy = {
    block1Title: 'Consultancy <br />& Discovery',
    block1Text1: '<p class="body18regular"> Great results require great planning, and in software solution building, that\'s no different. We like to think of our role in supporting you and your business as a journey, and in our experience, the best journeys begin here, in the, initial, Discovery stage.</p>',
    block1Text2: '<h3 class="h326bold"> One of the most important parts of this process is getting to know you, your business and all its needs. We make sure all stakeholders are involved here. </h3>',
    block2IllustrationArea: '<div class="illustration-alert-success"></div><div class="filled-frame-bl"><div class="illustration-service"></div></div>',
    block2TextArea: '<p class="body22light"><strong class="body22bold sgDarkGreen900">Solution building</strong>  means clearly identifying and defining the issues that require solving in the first place. If that sounds complicated, don\'t worry, it\'s not.</p><p class="body18light">Part of our role is to help you navigate and define these barriers to efficiency. Whether these issues are about communication, integration, automation or simply making things more efficient, in this Consultancy & Discovery Stage, you can be sure we\'ll get to the root of them all.</p>',
    block3Title: 'We typically have several key <br class="block3-brake" />steps in the whole process',
    block3Steps: [
        {
            nr: 1,
            contents:'<h5 class="body22semibold">Problem Definition</h5><p class="body16regular">We start by trying to clearly understand the problem or opportunity that the software is intended to address. This may involve conducting research, interviewing you, and gathering information about the needs and requirements for the software.</p>'
        },
        {
            nr: 2,
            contents:'<h5 class="body22semibold">Goal Setting</h5><p class="body16regular">Once the problem has been defined, we can set specific, measurable goals for the project.</p>'
        },
        {
            nr: 3,
            contents:'<h5 class="body22semibold">Feasibility Analysis</h5><p class="body16regular">We work on determining whether the project is technically and financially viable. This may involve evaluating the available technologies and determining whether they can be used to build the software, as well as assessing the costs and benefits of the project.</p>'
        },
        {
            nr: 4,
            contents:'<h5 class="body22semibold">Information Gathering</h5><p class="body16regular">We are then gathering additional information that will inform the design and development of the software on a couple of brainstorming sessions/meetings. This may include conducting user research, analyzing data, and reviewing existing systems and processes.</p>'
        },
        {
            nr: 5,
            contents:'<h5 class="body22semibold">Planning</h5><p class="body16regular">We\'ll use the information gathered during the discovery process to create a plan for the design and development of the software. This may include creating a project schedule, identifying the resources that will be needed, and outlining the specific tasks that will be required to build the software.</p>'
        },
        {
            nr: 6,
            contents:'<h5 class="body22semibold">Deliverables</h5><p class="body16regular">Deliverables: The discovery process culminates in a set of deliverables that document the findings of the project team and provide a foundation for the design and development of the software. These deliverables may include a project plan, a requirements document, user personas, and wireframes or mockups of the software.</p>'
        }
    ],
    block3StepsMob: [
        {
            nr: 1,
            title: '<h5 class="body22semibold">Problem Definition</h5>',
            contents:'<p class="body16regular">We start by trying to clearly understand the problem or opportunity that the software is intended to address. This may involve conducting research, interviewing you, and gathering information about the needs and requirements for the software.</p>'
        },
        {
            nr: 2,
            title: '<h5 class="body22semibold">Goal Setting</h5>',
            contents:'<p class="body16regular">Once the problem has been defined, we can set specific, measurable goals for the project.</p>'
        },
        {
            nr: 3,
            title: '<h5 class="body22semibold">Feasibility Analysis</h5>',
            contents:'<p class="body16regular">We work on determining whether the project is technically and financially viable. This may involve evaluating the available technologies and determining whether they can be used to build the software, as well as assessing the costs and benefits of the project.</p>'
        },
        {
            nr: 4,
            title: '<h5 class="body22semibold">Information Gathering</h5>',
            contents:'<p class="body16regular">We are then gathering additional information that will inform the design and development of the software on a couple of brainstorming sessions/meetings. This may include conducting user research, analyzing data, and reviewing existing systems and processes.</p>'
        },
        {
            nr: 5,
            title: '<h5 class="body22semibold">Planning</h5>',
            contents:'<p class="body16regular">We\'ll use the information gathered during the discovery process to create a plan for the design and development of the software. This may include creating a project schedule, identifying the resources that will be needed, and outlining the specific tasks that will be required to build the software.</p>'
        },
        {
            nr: 6,
            title: '<h5 class="body22semibold">Deliverables</h5>',
            contents:'<p class="body16regular">Deliverables: The discovery process culminates in a set of deliverables that document the findings of the project team and provide a foundation for the design and development of the software. These deliverables may include a project plan, a requirements document, user personas, and wireframes or mockups of the software.</p>'
        }
    ],
    block3MobIconClose:'<img src="/assets/img/Icon-arrow-down-black.svg" alt="">',
    block3MobIconOpen:'<img src="/assets/img/Icon-arrow-right-black.svg" alt="">',
    block4Text1:'<p class="body18light">The discovery process is critical for ensuring that the software project is well-defined and well-understood before development begins, which can help to reduce the risk of delays and unexpected costs later on. It also crucial in Agile and Scrum methodology, where the process is done in iterations and the deliverables of each iteration is the input of the next. </p>',
    block4Text2:'<p class="body18regular"><i>Keep in mind that the specific steps and deliverables involved in the discovery process can vary depending on the type of software being developed and the specific needs of the project.</i></p>',
    block4ScheduleCall:'Schedule a call',
    block4Image:'<img src="/assets/img/Mockup_Two2.png" alt="Some application"/>',
    block5Title:'<h5 class="body18light">See other services we offer</h5>',
    block5Link1:'Custom Software Development',
    block5Link2:'Post Delivery Maintenance & Support',
}

export const development = {
    block1Title: 'Custom Software <br> Development',
    block1Text1: '<p class="body18regular">Then comes the fun part! Now that we’ve clearly identified the issues that require solving, it’s time to get to work. Our Full Software Development stage is where the real magic happens. We are adept and experienced at developing tailor made solutions that are here to increase efficiencies and ultimately your client satisfaction. In this stage we’re able to jump into solution building that results in saving you time, money and effort. We can implement relevant integrations, so you have all the necessary tools to work effectively and efficiently. We focus on technologies like AWS services (as we store everything on the cloud), Node.js, Python, Angular and Ionic framework.</p>',
    block1Text2: '<h3 class="h326bold">But solution building for you and your business will look different than solution building for any other client. Your hurtles, needs, and areas of improvement are unique, and therefore so is the solution we’re going to give you.</h3>',
    block2IllustrationArea: '<div class="illustration-alert-success"></div><div class="filled-frame-bl"><div class="illustration-service"></div></div>',
    block2TextArea: '<p class="body22light"><strong class="body22bold sgDarkGreen900">We offer a wide range of services</strong>  to help businesses improve their processes and reduce the time and effort needed for day-to-day tasks.</p><p class="body18light">We utilize our skills and development knowledge, which have been honed over many years, to help businesses achieve their goals and stay competitive in their respective industries.</p>',
    block3Title: 'We typically have several key <br class="block3-brake" />steps in the whole process',
    block3Steps: [
        {
            nr: 1,
            contents:'<h5 class="body22semibold">Architecture planning</h5><p class="body16regular">The process involves creating a blueprint of the software system that specifies how different components of the system will interact with each other, what technologies will be used, and what design patterns will be implemented. The goal here is to ensure that the software system is designed in a way that meets the business\'s requirements and is scalable, maintainable, and reliable.</p>'
        },
        {
            nr: 2,
            contents:'<h5 class="body22semibold">Scrum planning and laying out a plan for execution</h5><p class="body16regular">We create a well-defined project plan that is executed in an agile manner, allowing the development team to quickly adapt to changes and deliver high-quality software solution.</p>'
        },
        {
            nr: 3,
            contents:'<h5 class="body22semibold">Development stage done in sprints</h5><p class="body16regular">Now that we have a full plan, development and coding part will kick off with daily update meetings and identifying, mitigating risks and proactively resolving all possible issue to ensure everything goes according to plan.</p>'
        },
        {
            nr: 4,
            contents:'<h5 class="body22semibold">Manual and automatic QA process</h5><p class="body16regular">Shortly after development process starts, we ensure that software application is functioning correctly and meeting the intended requirements. We use manual as well as automatic testing using specialized software tools. The combination of manual and automatic QA allows us to identify issues and defects in the software application quickly and efficiently.</p>'
        },
        {
            nr: 5,
            contents:'<h5 class="body22semibold">Final testing and demo</h5><p class="body16regular">We are nearly there, during every step, you are involved in the whole process and we try to listen and adapt, iron out all the final details and prepare the software for launch.</p>'
        },
        {
            nr: 6,
            contents:'<h5 class="body22semibold">Deployment live</h5><p class="body16regular">Final step in this whole process is launching the product and ensuring that everything is running smoothly.</p>'
        }
    ],
    block3StepsMob: [
        {
            nr: 1,
            title: '<h5 class="body22semibold">Architecture planning</h5>',
            contents:'<p class="body16regular">The process involves creating a blueprint of the software system that specifies how different components of the system will interact with each other, what technologies will be used, and what design patterns will be implemented. The goal here is to ensure that the software system is designed in a way that meets the business\'s requirements and is scalable, maintainable, and reliable.</p>'
        },
        {
            nr: 2,
            title: '<h5 class="body22semibold">Scrum planning and laying out a plan for execution</h5>',
            contents:'<p class="body16regular">We create a well-defined project plan that is executed in an agile manner, allowing the development team to quickly adapt to changes and deliver high-quality software solution.</p>'
        },
        {
            nr: 3,
            title: '<h5 class="body22semibold">Development stage done in sprints</h5>',
            contents:'<p class="body16regular">Now that we have a full plan, development and coding part will kick off with daily update meetings and identifying, mitigating risks and proactively resolving all possible issue to ensure everything goes according to plan.</p>'
        },
        {
            nr: 4,
            title: '<h5 class="body22semibold">Manual and automatic QA process</h5>',
            contents:'<p class="body16regular">Shortly after development process starts, we ensure that software application is functioning correctly and meeting the intended requirements. We use manual as well as automatic testing using specialized software tools. The combination of manual and automatic QA allows us to identify issues and defects in the software application quickly and efficiently.</p>'
        },
        {
            nr: 5,
            title: '<h5 class="body22semibold">Final testing and demo</h5>',
            contents:'<p class="body16regular">We are nearly there, during every step, you are involved in the whole process and we try to listen and adapt, iron out all the final details and prepare the software for launch.</p>'
        },
        {
            nr: 6,
            title: '<h5 class="body22semibold">Deployment live</h5>',
            contents:'<p class="body16regular">Final step in this whole process is launching the product and ensuring that everything is running smoothly.</p>'
        }
    ],
    block3MobIconClose:'<img src="/assets/img/Icon-arrow-down-black.svg" alt="">',
    block3MobIconOpen:'<img src="/assets/img/Icon-arrow-right-black.svg" alt="">',
    block4Text1:'<p class="body18light">Our team of skilled developers, designers, testers, project managers, and other specialists  ensure that  your business software is developed to the highest standards.</p>',
    block4Text2:'<p class="body18regular"><i>We have a deep understanding of software development principles, best practices, and the latest technologies and tools to build software applications that are scalable, maintainable, and reliable.</i></p>',
    block4ScheduleCall:'Schedule a call',
    block4Image:'<img src="/assets/img/Mockup_Two2.png" alt="Some application"/>',
    block5Title:'<h5 class="body18light">See other services we offer</h5>',
    block5Link1:'Consultancy & <br> Discovery',
    block5Link2:'Post Delivery Maintenance & Support',
}

export const support = {
    block1Title: 'Post Delivery <br> Maintenance & Support',
    block1Text1: '<p class="body18regular">Our software development company offers a comprehensive post-delivery and maintenance service to ensure that your software solution continues to meet your business needs over time.</p>',
    block1Text2: '<h3 class="h326bold">This service is designed to provide ongoing support, maintenance, and optimization of your software solution, allowing you to focus on your core business activities while we take care of your software needs.</h3>',
    block3Title: 'Here are some of services we offer <br class="block3-brake" />during this phase',
    block3Steps: [
        {
            nr: 1,
            contents:'<h5 class="body22semibold">Technical Support</h5><p class="body16regular">We provide technical support to ensure that your software solution continues to operate smoothly and efficiently. Our technical support team is available around the clock to help you with any issues that you may encounter.</p>'
        },
        {
            nr: 2,
            contents:'<h5 class="body22semibold">Bug Fixes and Updates</h5><p class="body16regular">We regularly monitor your software solution to identify and fix any bugs that may arise. We also provide regular updates to ensure that your software solution stays up-to-date with the latest technologies and features.</p>'
        },
        {
            nr: 3,
            contents:'<h5 class="body22semibold">Performance Optimization</h5><p class="body16regular">Our team of experts will optimize the performance of your software solution to ensure that it runs at maximum efficiency, providing you with the best possible user experience.</p>'
        },
        {
            nr: 4,
            contents:'<h5 class="body22semibold">Security Updates</h5><p class="body16regular">We provide regular security updates to ensure that your software solution is protected against any potential security threats.</p>'
        },
        {
            nr: 5,
            contents:'<h5 class="body22semibold">Feature Enhancements</h5><p class="body16regular">We provide feature enhancements to ensure that your software solution continues to meet your business needs over time. We work closely with you to understand your evolving business requirements and provide the necessary enhancements to your software solution.</p>'
        }
    ],
    block3StepsMob: [
        {
            nr: 1,
            title: '<h5 class="body22semibold">Technical Support</h5>',
            contents:'<p class="body16regular">We provide technical support to ensure that your software solution continues to operate smoothly and efficiently. Our technical support team is available around the clock to help you with any issues that you may encounter.</p>'
        },
        {
            nr: 2,
            title: '<h5 class="body22semibold">Bug Fixes and Updates</h5>',
            contents:'<p class="body16regular">We regularly monitor your software solution to identify and fix any bugs that may arise. We also provide regular updates to ensure that your software solution stays up-to-date with the latest technologies and features.</p>'
        },
        {
            nr: 3,
            title: '<h5 class="body22semibold">Performance Optimization</h5>',
            contents:'<p class="body16regular">Our team of experts will optimize the performance of your software solution to ensure that it runs at maximum efficiency, providing you with the best possible user experience.</p>'
        },
        {
            nr: 4,
            title: '<h5 class="body22semibold">Security Updates</h5>',
            contents:'<p class="body16regular">We provide regular security updates to ensure that your software solution is protected against any potential security threats.</p>'
        },
        {
            nr: 5,
            title: '<h5 class="body22semibold">Feature Enhancements</h5>',
            contents:'<p class="body16regular">We provide feature enhancements to ensure that your software solution continues to meet your business needs over time. We work closely with you to understand your evolving business requirements and provide the necessary enhancements to your software solution.</p>'
        }
    ],
    block3MobIconClose:'<img src="/assets/img/Icon-arrow-down-black.svg" alt="">',
    block3MobIconOpen:'<img src="/assets/img/Icon-arrow-right-black.svg" alt="">',
    block4Text1:'<p class="body18light">Overall, our post-delivery and maintenance service is designed to provide you with peace of mind, knowing that your software solution is in good hands.</p>',
    block4Text2:'<p class="body18regular"><i>We are committed to providing you with high-quality, reliable, and cost-effective software maintenance services that are tailored to meet your specific business needs.</i></p>',
    block4ScheduleCall:'Schedule a call',
    block4Image:'<img src="/assets/img/Mockup_Two2.png" alt="Some application"/>',
    block5Title:'<h5 class="body18light">See other services we offer</h5>',
    block5Link1:'Consultancy & <br> Discovery',
    block5Link2:'Custom Software Development',
}

export const casestudyicon = {
    block1Title: 'Icon Accounting CRM portal',
    block1Text: '<p class="body18regular"><span class="body18bold">Check out our case study for CRM software we created </span> for Icon Accounting, accountancy agency based in Dublin, Ireland.</p>',
    scheduleCall: 'Schedule a call',
    block1Image: '<img src="/assets/img/Mockup_3.svg" alt="Some application">',
    block2Title: 'About the client',
    block2Text: '<span class="body22GreenBold">Accountancy services</span><br><p class="mt-12">Icon Accounting is a Dublin-based company primarily focusing on accountancy services for contractors.  Now that we’ve clearly identified the issues that require solving, it’s time to get to work. We can implement relevant integrations, so you have all the necessary tools to work effectively and efficiently.</p>',
    block3Title: 'Products / services used',
    block3Pills: ['Python', 'Django', 'mySQL', 'HTML', 'Ionic', 'CSS', 'JL', 'Amazon AWS'],
    block4Title: 'Results',
    block4Text1: 'Client and Admin web portals / Mobile responsive',
    block4Text2: 'Mobile app / iOS & Android',
    block4Text2mob: 'Mobile app / iOS',
    block4Text3mob: 'Mobile app / Android',
    block4Image1: '<img src="/assets/img/Devices-white.svg" alt="Some application"/>',
    block4Image2: '<img src="/assets/img/Apple-white.svg" alt="Some application"/>',
    block4Image3: '<img src="/assets/img/Android-white.svg" alt="Some application"/>',
    block5Image1: '<img src="/assets/img/Mockup_9.svg" alt="Some application"/>',
    block5Image2: '<img src="/assets/img/Mockup_8.svg" alt="Some application"/>',
    block6Title: 'Goals and Challenges',
    block6Row1: '<div class="block6-pill">1</div><div class="block6-text">Reducing time spent on manual work and giving full transparency to the clients.</div>',
    block6Row2: '<div class="block6-pill">2</div><div class="block6-text">Client had a high number of clients (contractors) with specific needs and no centralized place (software) where they can manage their clients day-to-day so that everyone is updated in a timely manner.</div>',
    block6Row3: '<div class="block6-pill">3</div><div class="block6-text">Multiple different companies that client collaborate with and no clear way of communicating with them and tracking progress (whether it’s in relation to invoicing, payments, company incorporations for their clients, reporting).</div>',
    block6Row4: '<div class="block6-pill">4</div><div class="block6-text">The whole business is niche specific and it requires a completely tailor-made solution that would increase the efficiency and productivity of their employees as well as the satisfaction of their clients.</div>',
    block7Title: 'Solution',
    block7Text: 'In the initial discovery meetings the MVP idea was to create a CRM-like software where the client would be able to manage all data (personal data, documents) about their clients. Part of the solution was to create a client portal for end-users where everyone who is a client of Icon Accounting can get a user account and access the portal where they can communicate with their account managers in a timely manner, upload all necessary files and documents, submit expenses, timesheets, and invoices and get notifications about their payments and payslips.',
    block8Image: '<img src="/assets/img/Mockup_10.svg" alt="Some application"/>',
    block9Text1: 'Initial MVP was a huge success as it already reduced the time previously spent on managing clients in various different tools (old CRM software, excel sheets, Hubspot).',
    block9Text2: 'Due to the fact that the scope of services offered by our client expanded over time, we adapted and developed a full mobile app for clients where they can use the portal on the go with logging all expenses, timesheets, and documents and being just a click away from invoicing.',
    block9Image1: '<img src="/assets/img/Mockup_5.svg" alt="Some application"/>',
    block9Image2: '<img src="/assets/img/Mockup_4.svg" alt="Some application"/>',
    block9Image3: '<img src="/assets/img/Mockup_6.svg" alt="Some application"/>',
    block10Title: 'Integrations implemented over time',
    block10Image1: '<img src="/assets/img/Signature.svg" alt="Some application"/>',
    block10Image2: '<img src="/assets/img/Key.svg" alt="Some application"/>',
    block10Image3: '<img src="/assets/img/Wallet.svg" alt="Some application"/>',
    block10Image4: '<img src="/assets/img/Chat.svg" alt="Some application"/>',
    block10Image5: '<img src="/assets/img/Mockup_7.svg" alt="Some application"/>',
    block10Image6: '<img src="/assets/img/Checkmark.svg" alt="Some application"/>',
    block10SubTitle1: 'Adobe EchoSign',
    block10SubTitle2: 'Id Pal',
    block10SubTitle3: 'Stripe payments',
    block10SubTitle4: 'Live chat integrations',
    block10SubTitle5: 'Salesforce integration for a sales team',
    block10SubTitle6: 'Metabase reporting system',
    block10Text1: 'Created an API integration where the process of signing contracts, T&C’s, and other legal documents was reduced to one click.',
    block10Text2: 'API integration with KYC software that verifies the identity and ID documents of clients.',
    block10Text3: 'Integration with payment provider where clients can pay a one-time fee for the services of Icon accounting',
    block10Text4: 'Integration with 3rd party live chat solution',
    block10Text5: 'Custom integration with one unified inbox where our software can pull out attached documents and read emails so it can automatically insert the data into the portal. <br><br> Part of add-on integrations for payroll and payment reconciliation custom software where we can read into PDFs created by Icon, and import all important data to our payroll software',
    block10Text6: 'Setup salesforce structure that corresponds client’s way of working Custom integration where the sales team can keep track of leads in Salesforce, but once the conversion happens, there is automation in place to automatically create client profiles and guide them through an approval process.',
    block10Text7: 'Amazon SNS and SES (automated notifications to clients based on their VAT due dates and other important dates that clients should be wary of.',
    block10Text8: 'Custom full integration with Company bureau (a company specialized in company incorporation formations).',
    block10Text9: 'Integration with Kashflow (accounting, bookkeeping, and payroll software)',
    block10Text10: 'Integration with an open-source reporting tool so all the data analyzed and collected over time can be displayed to a client in reports',
    block11Title: 'The Results',
    block12Title1: '-70%',
    block12Title2: 'manual <br> work',
    block12Title3: 'Better overview',
    block12Title4: 'Higher conversion',
    block12Text1: '<span>Time spent on manual work was reduced by 70%</span> (due to all integrations and custom processes implemented, clients are automatically guided from the sign-up process to becoming a full client).',
    block12Text2: 'Clients have a much <span>better overview of their financials, invoices, expectations.</span>',
    block12Text3: 'Transparency and ease of communication between Icon Accounting employees and their clients increased over time leading to <span>much higher satisfaction and conversion rate from lead to client.</span>',
    block13Title1: 'Rockwell',
    block13Title2: 'Borgo',
    block13Text: 'Check our case studies and success stories <br class="block13-brake"> from our clients',
    block13Text1: 'Rockwell Logic portal is a cutting-edge CRM platform tailored to meet the unique needs of financial planning and wealth management agencies.',
    block13Text2: 'Borgo Accounting has been facing the challenges of scattered client data, manual processes, and fragmented tools.',
    block13Pills: ['Node.js', 'Angular', 'PostgreSQL', 'Amazon Cloud', '+5 more'],
    block13Pills2: ['Node.js', 'Angular', 'PostgreSQL', 'Amazon Cloud', '+5 more'],
    block13Image: '<img src="/assets/img/Mockup_1.svg" alt="Some application"/>',
    block13Image2: '<img src="/assets/img/Mockup_27.svg" alt="Some application"/>',
}

export const casestudyrockwell = {
    block1Title: 'Revolutionizing Financial Planning with Logic portal',
    block1Text: '<p class="body18regular">In the dynamic landscape of financial planning and wealth management, staying competitive requires <span class="body18bold">agility, efficiency, and exceptional client service.</span></p>',
    scheduleCall: 'Schedule a call',
    block1Image: '<img src="/assets/img/Mockup_11.svg" alt="Some application">',
    block2Title: 'About the client',
    block2Text: '<span class="body22GreenBold">Financial management</span><br><p class="mt-12">Rockwell Logic portal is a cutting-edge CRM platform tailored to meet the unique needs of financial planning and wealth management agencies. This case study explores how it has transformed operations at Rockwell Financial Management.</p>',
    block3Title: 'Technologies',
    block3Pills: ['Node.js', 'Angular/Ionic framework', 'PostgreSQL database', 'Amazon Cloud services (SES, SNS, EC2)'],
    block4Title: 'Results',
    block4Text1: 'Client and Admin web portals / Mobile responsive',
    block4Text2: 'Mobile app / iOS & Android',
    block4Text2mob: 'Mobile app / iOS',
    block4Text3mob: 'Mobile app / Android',
    block4Image1: '<img src="/assets/img/Devices-white.svg" alt="Some application"/>',
    block4Image2: '<img src="/assets/img/Apple-white.svg" alt="Some application"/>',
    block4Image3: '<img src="/assets/img/Android-white.svg" alt="Some application"/>',
    block5Image1: '<img src="/assets/img/Mockup_12.svg" alt="Some application"/>',
    block5Image2: '<img src="/assets/img/Mockup_13.svg" alt="Some application"/>',
    block5Image3: '<img src="/assets/img/Mockup_14.svg" alt="Some application"/>',
    block5Image4: '<img src="/assets/img/Mockup_15.svg" alt="Some application"/>',
    block6Title: 'Goals and Challenges',
    block6Row1: '<div class="block6-pill">1</div><div class="block6-text"><span class="body18bold">Data Fragmentation</span><br><br>Client information was scattered across various systems, making it difficult to access and update, leading to inefficiencies in servicing clients. They have been using various different tools for managing their day to day activities (anything from Excel, Word, OneNote, paper notes etc..)</div>',
    block6Row2: '<div class="block6-pill">2</div><div class="block6-text"><span class="body18bold">Complex Onboarding</span><br><br>Collecting comprehensive client data for product recommendations and onboarding was a time-consuming, error-prone process.</div>',
    block6Row3: '<div class="block6-pill">3</div><div class="block6-text"><span class="body18bold">Document Management</span><br><br>The manual preparation and customization of documents for signing and client communication were resource-intensive.</div>',
    block6Row4: '<div class="block6-pill">4</div><div class="block6-text"><span class="body18bold">Policy Information Management</span><br><br>Handling policy information from third-party providers was disjointed and lacked centralized visibility.</div>',
    block7Title: 'Solution',
    block7Text: 'In the initial discovery meetings the MVP idea was to create a CRM-like software where the client would be able to manage all data (personal data, documents) about their clients. Part of the solution was to create a client portal for end-users where everyone who is a client of Icon Accounting can get a user account and access the portal where they can communicate with their account managers in a timely manner, upload all necessary files and documents, submit expenses, timesheets, and invoices and get notifications about their payments and payslips.',
    block7Image1: '<img src="/assets/img/Mockup_19.svg" alt="Some application"/>',
    block7Image2: '<img src="/assets/img/Mockup_20.svg" alt="Some application"/>',
    block8Image: '<img src="/assets/img/Mockup_10.svg" alt="Some application"/>',
    block9Text1: 'Initial MVP was a huge success as it already reduced the time previously spent on managing clients in various different tools (old CRM software, excel sheets, Hubspot).',
    block9Text2: 'Due to the fact that the scope of services offered by our client expanded over time, we adapted and developed a full mobile app for clients where they can use the portal on the go with logging all expenses, timesheets, and documents and being just a click away from invoicing.',
    block9Image1: '<img src="/assets/img/Mockup_5.svg" alt="Some application"/>',
    block9Image2: '<img src="/assets/img/Mockup_4.svg" alt="Some application"/>',
    block9Image3: '<img src="/assets/img/Mockup_6.svg" alt="Some application"/>',
    block10Title: 'Solution',
    block10Title2: 'The Results',
    block10TitleSmall: 'Logic Portal was the comprehensive solution that transformed Rockwell’s day-to-day activities:',
    block10TitleSmall2: 'The implementation of Logic produced remarkable results for Rockwell financial management',
    block10Image1: '<img src="/assets/img/code-fork.svg" alt="Some application"/>',
    block10Image2: '<img src="/assets/img/arrow-narrow-circle-broken-down.svg" alt="Some application"/>',
    block10Image3: '<img src="/assets/img/files.svg" alt="Some application"/>',
    block10Image4: '<img src="/assets/img/cube-alt-2.svg" alt="Some application"/>',
    block10Image5: '<img src="/assets/img/Mockup_16.svg" alt="Some application"/>',
    block10Image6: '<img src="/assets/img/Icon.svg" alt="Some application"/>',
    block10Image7: '<img src="/assets/img/users.svg" alt="Some application"/>',
    block10Image8: '<img src="/assets/img/Mockup_17.svg" alt="Some application"/>',
    block10Image9: '<img src="/assets/img/Mockup_18.svg" alt="Some application"/>',
    block10Image10: '<img src="/assets/img/Checkmark.svg" alt="Some application"/>',
    block10SubTitle1: 'Unified Client Data',
    block10SubTitle2: 'Streamlined Onboarding',
    block10SubTitle3: 'Effortless Document Management',
    block10SubTitle4: 'Policy Information Centralization',
    block10SubTitle5: 'BI Analytics',
    block10SubTitle6: 'Client Portal',
    block10SubTitle7: 'Operational Efficiency',
    block10SubTitle8: 'Precision Recommendations',
    block10SubTitle9: 'Time and Cost Savings',
    block10SubTitle10: 'Centralized Policy Data',
    block10SubTitle11: 'Informed Decision-Making',
    block10SubTitle12: 'Enhanced Client Experience',
    block10Text1: 'The CRM centralizes all client information, providing a single source of truth for personal data. 3rd Party integration with Insightly, a CRM for tracking leads was created, so any client that is converted from Lead to Contact is automatically synced with Logic CRM.',
    block10Text2: 'Logic introduced a seamless onboarding process, including the extensive Fact Find questionnaire, enabling advisors to propose suitable products with precision.',
    block10Text3: 'Integration with Docusign allowed automated document preparation and signature collection, while a real-time HTML editor empowered admin users to fine-tune documents.',
    block10Text4: 'Integration with third-party policy providers centralized policy data within the platform, accessible from client profiles.',
    block10Text5: 'Logic introduced an extensive reporting tool that empowers administrators to generate insights, track earnings, commissions, and more, based on custom criteria.',
    block10Text6: 'The software created a full-fledged client portal, offering buying customers a wealth of services, including product tracking, communication with advisors, appointment scheduling, and financial calculators.',
    block10Text7: 'Client data is now easily accessible and up-to-date, reducing administrative overhead.',
    block10Text8: 'The Fact Find process enables advisors to provide tailored product recommendations swiftly and accurately.',
    block10Text9: 'Automated document management and integration with Docusign streamlined the signing process, reducing manual effort.',
    block10Text10: 'Policy information from third-party providers is now accessible within the CRM, simplifying policy management.',
    block10Text11: 'The BI analytics tool provides valuable insights into earnings and commissions, facilitating data-driven decisions.',
    block10Text12: 'The client portal offers a user-friendly platform for customers to manage their investments and communicate with advisors.',
    block11Title: 'The Results',
    block12Title1: 'Conclusion',
    block12Text1: 'Logic portal has transformed Rockwell financial management’s day to day work, turning challenges into opportunities for growth and exceptional client service. By centralizing data, streamlining onboarding, automating document management, and offering powerful analytics and a client portal, Logic portal has set new standards in financial planning and wealth management.',
    block13Title1: 'Icon Accounting',
    block13Title2: 'Borgo',
    block13Text: 'Check our case studies and success stories <br class="block13-brake"> from our clients',
    block13Text1: 'Icon Accounting is an accounting company from <br class="block13-brake"> Dublin, Ireland. Check out full case study below <br class="block13-brake"> showcasing different integrations that we did.',
    block13Text2: 'Borgo Accounting has been facing the challenges of scattered client data, manual processes, and fragmented tools.',
    block13Pills: ['Python', 'Django', 'mySQL', 'HTML', '+5 more'],
    block13Pills2: ['Node.js', 'Angular', 'PostgreSQL', 'Amazon Cloud', '+5 more'],
    block13Image: '<img src="/assets/img/Mockup_28.svg" alt="Some application"/>',
    block13Image2: '<img src="/assets/img/Mockup_27.svg" alt="Some application"/>',
}

export const casestudyborgo = {
    block1Title: 'Streamlining Accounting Agency Operations with Borgo CRM software',
    block1Text: '<p class="body18regular">In the dynamic landscape of financial planning and wealth management, staying competitive requires <span class="body18bold">agility, efficiency, and exceptional client service.</span></p>',
    scheduleCall: 'Schedule a call',
    block1Image: '<img src="/assets/img/Mockup_21.svg" alt="Some application">',
    block2Title: 'About the client',
    block2Text: '<span class="body22GreenBold">Powerful Accounting Platform</span><br><p class="mt-12">In the ever-evolving world of accounting and financial management, efficiency and organization are paramount. Borgo Accounting has been facing the challenges of scattered client data, manual processes, and fragmented tools. Enter Borgo CRM, a cutting-edge accounting management solution designed to revolutionize their operations.</p>',
    block3Title: 'Technologies',
    block3Pills: ['Node.js', 'Angular/Ionic framework', 'PostgreSQL database', 'Amazon Cloud services (SES, SNS, EC2)'],
    block4Title: 'Results',
    block4Text1: 'Client and Admin web portals / Mobile responsive',
    block4Text2: 'Mobile app / iOS & Android',
    block4Text2mob: 'Mobile app / iOS',
    block4Text3mob: 'Mobile app / Android',
    block4Image1: '<img src="/assets/img/Devices-white.svg" alt="Some application"/>',
    block4Image2: '<img src="/assets/img/Apple-white.svg" alt="Some application"/>',
    block4Image3: '<img src="/assets/img/Android-white.svg" alt="Some application"/>',
    block5Image1: '<img src="/assets/img/Mockup_22.svg" alt="Some application"/>',
    block5Image2: '<img src="/assets/img/Mockup_23.svg" alt="Some application"/>',
    block6Title: 'Goals and Challenges',
    block6Row1: '<div class="block6-pill">1</div><div class="block6-text"><span class="body18bold">Data Disarray</span><br><br>Client information, including company names, VAT details, important dates, and contact information, was scattered across various systems and documents. Retrieving crucial data was a time-consuming ordeal.</div>',
    block6Row2: '<div class="block6-pill">2</div><div class="block6-text"><span class="body18bold">Manual Labor Overload</span><br><br>Without a centralized system, Borgo employees were burdened with repetitive, manual tasks that consumed valuable time and resources.</div>',
    block6Row3: '<div></div>',
    block6Row4: '<div class="block6-pill">3</div><div class="block6-text"><span class="body18bold">Lack of Visibility</span><br><br>The absence of real-time KPI tracking made it difficult for the agency to monitor their clients financial health and provide timely insights.</div>',
    block7Title: 'Solution',
    block7Text: 'In the initial discovery meetings the MVP idea was to create a CRM-like software where the client would be able to manage all data (personal data, documents) about their clients. Part of the solution was to create a client portal for end-users where everyone who is a client of Icon Accounting can get a user account and access the portal where they can communicate with their account managers in a timely manner, upload all necessary files and documents, submit expenses, timesheets, and invoices and get notifications about their payments and payslips.',
    block8Image: '<img src="/assets/img/Mockup_10.svg" alt="Some application"/>',
    block9Text1: 'Initial MVP was a huge success as it already reduced the time previously spent on managing clients in various different tools (old CRM software, excel sheets, Hubspot).',
    block9Text2: 'Due to the fact that the scope of services offered by our client expanded over time, we adapted and developed a full mobile app for clients where they can use the portal on the go with logging all expenses, timesheets, and documents and being just a click away from invoicing.',
    block9Image1: '<img src="/assets/img/Mockup_5.svg" alt="Some application"/>',
    block9Image2: '<img src="/assets/img/Mockup_4.svg" alt="Some application"/>',
    block9Image3: '<img src="/assets/img/Mockup_6.svg" alt="Some application"/>',
    block10Title: 'Solution',
    block10Title2: 'The Results',
    block10TitleSmall: 'Borgo CRM was the answer to their problems:',
    block10TitleSmall2: 'The implementation of Borgo CRM yielded remarkable results for the company',
    block10Image1: '<img src="/assets/img/code-fork.svg" alt="Some application"/>',
    block10Image2: '<img src="/assets/img/Mockup_25.svg" alt="Some application"/>',
    block10Image3: '<img src="/assets/img/gear.svg" alt="Some application"/>',
    block10Image4: '<img src="/assets/img/pen-line.svg" alt="Some application"/>',
    block10Image5: '<img src="/assets/img/Mockup_24.svg" alt="Some application"/>',
    block10Image8: '<img src="/assets/img/Mockup_26.svg" alt="Some application"/>',
    block10Image10: '<img src="/assets/img/Checkmark.svg" alt="Some application"/>',
    block10SubTitle1: 'Centralized Data Repository',
    block10SubTitle2: 'Seamless Integration',
    block10SubTitle3: 'Enhanced Efficiency',
    block10SubTitle4: 'WYSIWYG Editor',
    block10SubTitle7: 'Time Savings',
    block10SubTitle8: 'Data Accuracy',
    block10SubTitle9: 'Enhanced Client Relations',
    block10SubTitle10: 'Paperless Processes',
    block10Text1: 'The software provides a unified platform where all client data, from company names to VAT information, could be stored, accessed, and updated in real-time.',
    block10Text2: 'Borgo CRM seamlessly integrated with Xero, a 3rd party banking software, enabling Borgo employees to fetch reports for their clients and set up KPI graphs that were accessible via a client portal.',
    block10Text3: "The software's e-approvals feature streamlined document processing, while integration with Adobe EchoSign simplified contract signing, eliminating the need for manual paperwork.",
    block10Text4: 'It also contains an in-app WYSIWYG editor, enabling Borgo employees to create and edit legal documents effortlessly before sending them for signing or approval.',
    block10Text7: 'Manual data entry and paperwork were significantly reduced, allowing employees to focus on higher-value tasks and client relationships.',
    block10Text8: 'With centralized data storage, errors from duplicated or outdated information became a thing of the past.',
    block10Text9: 'Real-time KPI graphs and a client portal with mobile access improved client engagement and satisfaction.',
    block10Text10: 'The integration with Adobe EchoSign eliminated paper-based document handling, saving both time and resources.',
    block11Title: 'The Results',
    block12Title1: 'Conclusion',
    block12Text1: 'Borgo CRM transformed their day to day business by addressing their key challenges head-on. <span class="block12Bold">It consolidated client data, automated processes, and empowered the agency to provide superior services to their clients.</span> In an industry that thrives on accuracy and efficiency. <br><br> Is your organization facing similar challenges? Contact us now to find out how can you implement the same and reduce manual labor in your day-to-day activities.',
    block13Title1: 'Icon Accounting',
    block13Title2: 'Rockwell',
    block13Text: 'Check our case studies and success stories <br class="block13-brake"> from our clients',
    block13Text1: 'Icon Accounting is an accounting company from <br class="block13-brake"> Dublin, Ireland. Check out full case study below <br class="block13-brake"> showcasing different integrations that we did.',
    block13Text2: 'Rockwell Logic portal is a cutting-edge CRM platform tailored to meet the unique needs of financial planning and wealth management agencies.',
    block13Pills: ['Python', 'Django', 'mySQL', 'HTML', '+5 more'],
    block13Pills2: ['Node.js', 'Angular', 'PostgreSQL', 'Amazon Cloud', '+5 more'],
    block13Image: '<img src="/assets/img/Mockup_28.svg" alt="Some application"/>',
    block13Image2: '<img src="/assets/img/Mockup_1.svg" alt="Some application"/>',
}