import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-casestudyrockwell',
  templateUrl: './casestudyrockwell.component.html',
  styleUrls: ['./casestudyrockwell.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CasestudyrockwellComponent implements OnInit {

  public contents = environment.contents.casestudyrockwell;

  private title = 'Selected Green - Rockwell Logic portal';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
