<div class="header-container">
    <div class="header-blur-container"></div>
    <div class="header-inner-container container">
        <a class="logo-container" href="/">
            <img src="/assets/img/Logo.svg" alt="SelectedGreen"/>
        </a>
        <nav class="nav-container">
            <a class="btn btn-transparent" [routerLink]="['/']">Home</a>
            <div class="btn btn-transparent submenu">
                <span>Services</span>
                <div class="submenu-container">
                    <a class="btn btn-transparent" [routerLink]="['/consultancy']">Constultancy & Discovery</a>
                    <a class="btn btn-transparent" [routerLink]="['/development']">Custom Software Development</a>
                    <a class="btn btn-transparent" [routerLink]="['/support']">Post Delivery Maintenance & Support</a>
                </div>
            </div>
            <div class="btn btn-transparent submenu">
                <span>Work</span>
                <div class="submenu-container">
                    <a class="btn btn-transparent" [routerLink]="['/casestudyicon']">Icon Accounting</a>
                    <a class="btn btn-transparent" [routerLink]="['/casestudyrockwell']">Rockwell</a>
                    <a class="btn btn-transparent" [routerLink]="['/casestudyborgo']">Borgo</a>
                </div>
            </div>
            <!-- <a class="btn btn-transparent" [routerLink]="['/casestudy']" [queryParams]="{name: 'icon'}">Work</a> -->
            <a class="btn btn-sgGreen" [routerLink]="" (click)="goToBottom()">Contact Us</a>
        </nav>
        <!-- Mobile menu start-->
        <a class="logo-container-mob" href="/">
            <img src="/assets/img/Logo_Mobile.svg" alt="SelectedGreen"/>
        </a>
        <div class="nav-container-mob">
            <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu" #open_MobMenu (click)="isOpen(open_MobMenu)">
            <label for="openSidebarMenu" class="sidebarIconToggle">
                <div class="spinner diagonal part-1"></div>
                <div class="spinner horizontal"></div>
                <div class="spinner diagonal part-2"></div>
            </label>
        </div>
        <!-- Mobile menu end-->
    </div>
    <!-- Mobile menu start-->
    <div class="mobMenuContainer" [style.display]="open_MobMenu.checked ? 'block' : 'none'" (click)="backdrop(open_MobMenu)">
        <div class="mobMenu-flex" #backdrop_MobMenu (click)="backdrop(open_MobMenu)">
            <a class="btn btn-transparent" [routerLink]="['/']">Home</a>
            <a class="mobMenu-item-flex btn btn-transparent" #servicesOpen (click)="servicesClick(servicesOpen, $event, backdrop_MobMenu2)">
                <div>Services</div>
                <div class="mob-icon-container">
                    <img src="/assets/img/Icon-arrow-right-black.svg" alt="">
                </div>
            </a>
            <a class="mobMenu-item-flex btn btn-transparent" #workOpen (click)="workClick(workOpen, $event, backdrop_MobMenu3)">
                <div>Work</div>
                <div class="mob-icon-container">
                    <img src="/assets/img/Icon-arrow-right-black.svg" alt="">
                </div>
            </a>
        </div>
        <div class="mobMenu-flex" style="display: none;" #backdrop_MobMenu2 (click)="backdrop(open_MobMenu)">
            <a class="mobMenu-item-flex2 btn btn-transparent" #servicesOpen (click)="servicesClick(servicesOpen, $event, backdrop_MobMenu2)">
                <div class="mob-icon-container">
                    <img src="/assets/img/Icon-arrow-left-black.svg" alt="">
                </div>
                <div>Services</div>
            </a>
            <a class="btn btn-transparent" [routerLink]="['/consultancy']">Constultancy & Discovery</a>
            <a class="btn btn-transparent" [routerLink]="['/development']">Custom Software Development</a>
            <a class="btn btn-transparent" [routerLink]="['/support']">Post Delivery Maintenance & Support</a>
        </div>
        <div class="mobMenu-flex" style="display: none;" #backdrop_MobMenu3 (click)="backdrop(open_MobMenu)">
            <a class="mobMenu-item-flex2 btn btn-transparent" #workOpen (click)="workClick(workOpen, $event, backdrop_MobMenu3)">
                <div class="mob-icon-container">
                    <img src="/assets/img/Icon-arrow-left-black.svg" alt="">
                </div>
                <div>Work</div>
            </a>
            <a class="btn btn-transparent" [routerLink]="['/casestudyicon']">Icon Accounting</a>
            <a class="btn btn-transparent" [routerLink]="['/casestudyrockwell']">Rockwell</a>
            <a class="btn btn-transparent" [routerLink]="['/casestudyborgo']">Borgo</a>
        </div>
    </div>
    <!-- Mobile menu end-->
</div>