<div class="footer-contaner" id="footer">
    <div class="footer-inner-container container">
        <div class="title" [innerHtml]="contents.getInTouchTitle">Get in Touch</div>
        <div class="footer-flex">
            <div class="footer-elem" >
                <div [innerHtml]="contents.lookingProjectEstimate" class="mb-60"></div>
                <a class="btn btn-black plausible-event-name=Schedule+a+call+Click" [routerLink]="['/schedulecall']" [innerHtml]="contents.scheduleACallButton"></a>
            </div>
            <div class="footer-elem">
                <div class="footer-text" [innerHtml]="contents.address"></div>
                <a class="footer-link-icon" href="tel:{{contents.phone}}">
                    <img src="/assets/img/Phone.svg" alt="phone" />
                    <span class="body18bold">{{contents.phone}}</span>
                </a>
                <a class="footer-link-icon plausible-event-name=Email+Click" href="mailto:{{contents.email}}" (click)="trackConversion('mailto:hello@selectedgreen.com?subject=Business%20inquiry&body=Hello%20Selected%20Green!%0A%0AOur%20company%20name%20is%3A%0AWe%20want%20to%20integrate%3A%0A%0AWe%20have%20_%20months%20to%20deliver%20the%20final%20product.%0AOur%20budget%20is%20in%20range%20between%20x.000%20and%20xx.000EUR.%0ATech%20stack%20is%20based%20on%20(Microsoft%2C%20Amazon%2C%20Google)%0A%0AHear%20from%20you%20soon!%0A')">
                    <img src="/assets/img/Mail.svg" alt="phone" />
                    <span class="body18bold">{{contents.emailVisible}}</span>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="footer-copy">
    <div class="container" [innerHtml]="contents.copyRight"></div>
</div>