<div class="consultancy-block1 container-fluid">
    <div class="container">
        <h1 class="h240bold" [innerHtml]="contents.block1Title"></h1>
        <div class="flexed-container">
            <div class="section1" [innerHtml]="contents.block1Text1"></div>
            <div class="section2" [innerHtml]="contents.block1Text2"></div>
        </div>
    </div>
</div>
<div class="consultancy-block2 container">
    <div class="illustration" [innerHtml]="contents.block2IllustrationArea"></div>
    <div class="text" [innerHtml]="contents.block2TextArea"></div>
</div>
<div class="consultancy-block3">
    <h2 class="h240bold" [innerHTML]="contents.block3Title"></h2>
    <!-- Desktop start -->
    <div class="wide-block">
        <div class="container">
            <div class="inner-block">
                <div *ngFor="let step of contents.block3Steps; index as i; count as c">
                    <div class="number-container">{{step.nr}}</div>
                    <div class="contents-container" [innerHTML]="step.contents"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- Desktop end -->
    <!-- Mobile start -->
    <div class="wide-block-mob">
        <div class="mob-illustration"></div>
        <div class="container">
            <div class="wide-block-mob-container" *ngFor="let step of contents.block3StepsMob; index as i; count as c">
                <div class="wide-block-mob-flexed">
                    <div class="number-container">{{step.nr}}</div>
                    <div class="text-container" [innerHTML]="step.title"></div>
                    <div class="block-mob-arrow" (click)="handleClick($event)" [innerHTML]="contents.block3MobIconOpen"></div>
                </div>
                <div class="contents-container-mob-hide" [innerHTML]="step.contents"></div>
            </div>
        </div>
    </div>
    <!-- Mobile end -->
</div>
<div class="consultancy-block4 container">
    <div class="illustration"></div>
    <div class="flexed-container">
        <div class="section1">
            <div [innerHtml]="contents.block4Text1"></div>
            <div [innerHtml]="contents.block4Text2"></div>
            <a class="btn btn-lightSgGreen button-with-emoji plausible-event-name=Schedule+a+call+Click" [routerLink]="['/schedulecall']">
                <span [innerHtml]="contents.block4ScheduleCall"></span>
            </a>
        </div>
        <div class="section2">
            <div class="block4-img" [innerHtml]="contents.block4Image"></div>
        </div>
    </div>
</div>
<div class="other-what-we-do-container container">
    <div class="title-container" [innerHtml]="contents.block5Title"></div>
    <div class="panel-links-container">
        <a [routerLink]="['/consultancy']" class="panel-link">
            <div class="block-with-icon">
                <div class="icon icon-consultancy"></div>
            </div>
            <div class="link-label" [innerHtml]="contents.block5Link1"></div>
        </a>
        <a [routerLink]="['/support']" class="panel-link">
            <div class="block-with-icon">
                <div class="icon icon-service"></div>
            </div>
            <div class="link-label" [innerHtml]="contents.block5Link2"></div>
        </a>
    </div>
</div>
