import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  
  goToBottom() {
    window.scrollTo(0,document.body.scrollHeight);
  }

  isOpen(open_MobMenu:any) {
      open_MobMenu.checked ? false : true ;
      open_MobMenu.checked ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'visible' ;
  }
  
  backdrop (open_MobMenu:any,) {
    open_MobMenu.checked = false;
    document.body.style.overflowY = 'visible';
  }

  servicesClick(servicesOpen:any, event:any, backdrop_MobMenu2:any) {
    event.stopPropagation();
    if (servicesOpen.parentElement.style.display === 'none') {
      backdrop_MobMenu2.style = 'display : none';
      servicesOpen.parentElement.style = 'display : flex'
    } else {
      backdrop_MobMenu2.style = 'display : flex';
      servicesOpen.parentElement.style = 'display : none';
    }
  }

  workClick(workOpen:any, event:any, backdrop_MobMenu3:any) {
    event.stopPropagation();
    if (workOpen.parentElement.style.display === 'none') {
      backdrop_MobMenu3.style = 'display : none';
      workOpen.parentElement.style = 'display : flex'
    } else {
      backdrop_MobMenu3.style = 'display : flex';
      workOpen.parentElement.style = 'display : none';
    }
  }

}
