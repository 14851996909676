import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { WorkComponent } from './work/work.component';
import { SchedulecallComponent } from './schedulecall/schedulecall.component';
import { ConsultancyComponent } from './consultancy/consultancy.component';
import { DevelopmentComponent } from './development/development.component';
import { SupportComponent } from './support/support.component';
import { CasestudyComponent } from './casestudy/casestudy.component';
import { CasestudyiconComponent } from './casestudyicon/casestudyicon.component';
import { CasestudyrockwellComponent } from './casestudyrockwell/casestudyrockwell.component';
import { CasestudyborgoComponent } from './casestudyborgo/casestudyborgo.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    WorkComponent,
    SchedulecallComponent,
    ConsultancyComponent,
    DevelopmentComponent,
    SupportComponent,
    CasestudyComponent,
    CasestudyiconComponent,
    CasestudyrockwellComponent,
    CasestudyborgoComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
