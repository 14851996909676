import { home, consultancy, footer, header, casestudyicon, casestudyrockwell, casestudyborgo, development, support } from "../contents/contents";

export const environment = {
  production: false,
  
  contents: {
    footer: footer,
    header: header,
    home: home,
    consultancy: consultancy,
    development: development,
    support: support,
    casestudyicon: casestudyicon,
    casestudyrockwell: casestudyrockwell,
    casestudyborgo: casestudyborgo
  }
};
