import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupportComponent implements OnInit {

  private title = 'Selected Green - Post delivery services';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }


  public contents = environment.contents.support;

  handleClick(event:any) {
    const className1 = 'contents-container-mob-hide';
    const className2 = 'contents-container-mob-show';
    if (event.currentTarget.parentElement.parentElement.children[1].classList.contains(className1)) {
      event.currentTarget.parentElement.parentElement.children[1].classList.remove(className1);
      event.currentTarget.parentElement.parentElement.children[1].classList.add(className2);
      event.currentTarget.firstChild.attributes[0].value = '/assets/img/Icon-arrow-down-black.svg';
    } else {
      event.currentTarget.parentElement.parentElement.children[1].classList.add(className1);
      event.currentTarget.firstChild.attributes[0].value = '/assets/img/Icon-arrow-right-black.svg';
    }
  }

}
