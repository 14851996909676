<div class="home-container">
    <div class="container">
        <div class="home-block1">
            <div class="illustration-container"></div>
            <div class="block">
                <h1 class="underLined" [innerHtml]="contents.block1Title"></h1>
                <div [innerHtml]="contents.block1Text"></div>
                <a class="btn btn-lightSgGreen button-with-emoji plausible-event-name=Schedule+a+call+Click" [routerLink]="['/schedulecall']">
                    <span [innerHtml]="contents.scheduleCall"></span>
                    <img src="/assets/img/WaveEmoji.png" alt="Schedule a call" />
                </a>
            </div>
            <img src="/assets/img/mockup_one.svg" />
        </div>
        <div class="home-block2">
            <div class="illustration-container"></div>
            <div class="block-title">
                <h1 [innerHtml]="contents.block2Title"></h1>
                <p [innerHtml]="contents.block2SubTitle"></p>
            </div>
            <div class="block-grid">
                <div class="block-cell contains-frame" [innerHtml]="contents.block2Row1Img"></div>
                <div class="block-cell contains-text" [innerHtml]="contents.block2Row1Text"></div>
                <div class="block-cell contains-text" [innerHtml]="contents.block2Row2Text"></div>
                <div class="block-cell contains-frame" [innerHtml]="contents.block2Row2Img"></div>
                <div class="block-cell contains-frame" [innerHtml]="contents.block2Row3Img"></div>
                <div class="block-cell contains-text" [innerHtml]="contents.block2Row3Text"></div>
            </div>
            <!--Mobile view start-->
            <div class="block-grid-mob">
                <div class="block-mob-flex">
                    <div [innerHtml]="contents.block2Row1Img"></div>
                    <div class="block-mob-title" [innerHTML]="contents.block2MobRow1Title"></div>
                    <div class="block-mob-arrow" *ngIf="!blockMobIconOpen1" (click)="blockMobIcon1()" [innerHTML]="contents.block2MobRowIconOpen"></div>
                    <div class="block-mob-arrow" *ngIf="blockMobIconOpen1" (click)="blockMobIcon1()" [innerHTML]="contents.block2MobRowIconClose"></div>
                </div>
                <div *ngIf="blockMobIconOpen1" [innerHTML]="contents.block2MobRow1Text"></div>

                <div class="block-mob-flex">
                    <div [innerHtml]="contents.block2Row2Img"></div>
                    <div class="block-mob-title" [innerHTML]="contents.block2MobRow2Title"></div>
                    <div class="block-mob-arrow" *ngIf="!blockMobIconOpen2" (click)="blockMobIcon2()" [innerHTML]="contents.block2MobRowIconOpen"></div>
                    <div class="block-mob-arrow" *ngIf="blockMobIconOpen2" (click)="blockMobIcon2()" [innerHTML]="contents.block2MobRowIconClose"></div>
                </div>
                <div *ngIf="blockMobIconOpen2" [innerHTML]="contents.block2MobRow2Text"></div>

                <div class="block-mob-flex">
                    <div [innerHtml]="contents.block2Row3Img"></div>
                    <div class="block-mob-title" [innerHTML]="contents.block2MobRow3Title"></div>
                    <div class="block-mob-arrow" *ngIf="!blockMobIconOpen3" (click)="blockMobIcon3()" [innerHTML]="contents.block2MobRowIconOpen"></div>
                    <div class="block-mob-arrow" *ngIf="blockMobIconOpen3" (click)="blockMobIcon3()" [innerHTML]="contents.block2MobRowIconClose"></div>
                </div>
                <div *ngIf="blockMobIconOpen3" [innerHTML]="contents.block2MobRow3Text"></div>
                
            
            </div>
            <!--Mobile view end-->
            <div class="block-footer">
                <a [routerLink]="['/schedulecall']" class="btn btn-lightSgGreen">{{contents.block2ButtonText}}</a>
            </div>
            <div class="illustration-intersect-container"></div>
        </div>
        <div class="home-block3">
            <h1 [innerHtml]="contents.block3Title"></h1>
            <p class="block3-subtitle body18light" [innerHtml]="contents.block3Subtitle"></p>
            <!--<a [routerLink]="['/casestudy']" [queryParams]="{name: 'icon'}" class="panel">-->
                <a [routerLink]="['/casestudyicon']" class="panel b-margine">
                <div class="left-contents">
                    <h3 class="panel-title" [innerHtml]="contents.block3PanelItemTitle"></h3>
                    <p class="panel-subtitle" [innerHtml]="contents.block3PanelItemSubtitle"></p>
                    <div class="pills-container" *ngIf="contents.block3PanelItemPills && contents.block3PanelItemPills.length > 0">
                        <div class="pill" *ngFor="let pill of contents.block3PanelItemPills; index as i; count as c">{{pill}}</div>
                    </div>
                </div>
                <img [src]="contents.block3PanelItemImgUrl" alt="contents.block3PanelItemTitle" />
            </a>
        </div>

        <div class="home-block3">
                <a [routerLink]="['/casestudyrockwell']" class="panel b-margine">
                <div class="left-contents">
                    <h3 class="panel-title" [innerHtml]="contents.block3PanelItemTitle1"></h3>
                    <p class="panel-subtitle" [innerHtml]="contents.block3PanelItemSubtitle1"></p>
                    <div class="pills-container" *ngIf="contents.block3PanelItemPills1 && contents.block3PanelItemPills1.length > 0">
                        <div class="pill" *ngFor="let pill of contents.block3PanelItemPills1; index as i; count as c">{{pill}}</div>
                    </div>
                </div>
                <img [src]="contents.block3PanelItemImgUrl1" alt="contents.block3PanelItemTitle" />
            </a>
        </div>

        <div class="home-block3">
            <div class="illustration-container"></div>
                <a [routerLink]="['/casestudyborgo']" class="panel">
                <div class="left-contents">
                    <h3 class="panel-title" [innerHtml]="contents.block3PanelItemTitle2"></h3>
                    <p class="panel-subtitle" [innerHtml]="contents.block3PanelItemSubtitle2"></p>
                    <div class="pills-container" *ngIf="contents.block3PanelItemPills2 && contents.block3PanelItemPills2.length > 0">
                        <div class="pill" *ngFor="let pill of contents.block3PanelItemPills2; index as i; count as c">{{pill}}</div>
                    </div>
                </div>
                <img [src]="contents.block3PanelItemImgUrl2" alt="contents.block3PanelItemTitle" />
            </a>
        </div>
    </div>
</div>