import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WorkComponent } from './work/work.component';
import { SchedulecallComponent } from './schedulecall/schedulecall.component';
import { ConsultancyComponent } from './consultancy/consultancy.component';
import { DevelopmentComponent } from './development/development.component';
import { SupportComponent } from './support/support.component';
import { CasestudyComponent } from './casestudy/casestudy.component';
import { CasestudyiconComponent } from './casestudyicon/casestudyicon.component';
import { CasestudyrockwellComponent } from './casestudyrockwell/casestudyrockwell.component';
import { CasestudyborgoComponent } from './casestudyborgo/casestudyborgo.component';

const routes: Routes = [
	{
		path: 'home',
		component: HomeComponent
	},
	{
		path: 'consultancy',
		component: ConsultancyComponent
	},
	{
		path: 'development',
		component: DevelopmentComponent
	},
	{
		path: 'support',
		component: SupportComponent
	},
	{
		path: 'work',
		component: WorkComponent
	},
	{
		path: 'schedulecall',
		component: SchedulecallComponent
	},
	{
		path: 'casestudy',
		component: CasestudyComponent
	},
	{
		path: 'casestudyicon',
		component: CasestudyiconComponent
	},
	{
		path: 'casestudyrockwell',
		component: CasestudyrockwellComponent
	},
	{
		path: 'casestudyborgo',
		component: CasestudyborgoComponent
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
