import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  public contents = environment.contents.home;
  blockMobIconOpen1 = false;
  blockMobIconOpen2 = false;
  blockMobIconOpen3 = false;

  blockMobIcon1(){
    this.blockMobIconOpen1 = !this.blockMobIconOpen1;
  }
  blockMobIcon2(){
    this.blockMobIconOpen2 = !this.blockMobIconOpen2;
  }
  blockMobIcon3(){
    this.blockMobIconOpen3 = !this.blockMobIconOpen3;
  }

  constructor(private meta: Meta, private title: Title) {
    this.meta.addTags([
      {name: 'description', content: 'A custom software development company that understands your needs and reduces workflow within your company. ' },
      {name: 'keywords', content: 'custom software, development company'}
    ]);
    this.setTitle('Selected Green - Custom software development company');
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit() {
  }
}
