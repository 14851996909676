<div class="casestudyrockwell-block1">
    <div class="block1-flex-container container">
        <div class="section1">
            <div>
                <h1 class="h240bold" [innerHTML]="contents.block1Title"></h1>
                <div class="section1-block1text" [innerHTML]="contents.block1Text"></div>
                <a class="btn btn-lightSgGreen button-with-emoji plausible-event-name=Schedule+a+call+Click" [routerLink]="['/schedulecall']">
                    <div [innerHTML]="contents.scheduleCall"></div>
                </a>
            </div>
        </div>
        <div class="section2" [innerHTML]="contents.block1Image"></div>
    </div>
</div>

<div class="casestudyrockwell-block2 container">
    <h1 class="h240bold" [innerHTML]="contents.block2Title"></h1>
</div>

<div class="casestudyrockwell-block3 container">
    <div class="block3-flex-container">
        <div class="section1">
            <div class="body16regular" [innerHTML]="contents.block2Text"></div>
        </div>
        <div class="section2">
            <div class="casestudyrockwell-block3-title" [innerHTML]="contents.block3Title"></div>
            <div class="mt-12 pill-container">
                <div class="pill" *ngFor="let item of contents.block3Pills">
                    {{item}}
                </div>
            </div>
            <div class="casestudyrockwell-block4-title" [innerHTML]="contents.block4Title"></div>
            <div class="casestudyrockwell-block4-flex-container flex-first mt-12">
                <div class="casestudyrockwell-block4-img" [innerHTML]="contents.block4Image1"></div>
                <div class="casestudyrockwell-block4-text" [innerHTML]="contents.block4Text1"></div>
            </div>
            <!-- Desktop start -->
            <div class="casestudyrockwell-block4-flex-container block4-desktop mt-12">
                <div class="casestudyrockwell-block4-img" [innerHTML]="contents.block4Image2"></div>
                <div class="casestudyrockwell-block4-img" [innerHTML]="contents.block4Image3"></div>
                <div [innerHTML]="contents.block4Text2"></div>
            </div>
            <!-- Desktop end -->
            <!-- Mobile start -->
            <div class="casestudyrockwell-block4-flex-container block4-mobile mt-12">
                <div class="casestudyrockwell-block4-img" [innerHTML]="contents.block4Image2"></div>
                <div class="casestudyrockwell-block4-text" [innerHTML]="contents.block4Text2mob"></div>
            </div>
            <div class="casestudyrockwell-block4-flex-container block4-mobile mt-12">
                <div class="casestudyrockwell-block4-img" [innerHTML]="contents.block4Image3"></div>
                <div class="casestudyrockwell-block4-text" [innerHTML]="contents.block4Text3mob"></div>
            </div>
            <!-- Mobile end -->
        </div>
    </div>
</div>

<div class="casestudyrockwell-block5 container">
    <div class="block5-flex-container">
        <div [innerHTML]="contents.block5Image1"></div>
        <div [innerHTML]="contents.block5Image2"></div>
        <div [innerHTML]="contents.block5Image3"></div>
        <div [innerHTML]="contents.block5Image4"></div>
    </div>
</div>

<div class="casestudyrockwell-block6">
    <div class="container">
        <div class="block6-illustration"></div>
        <div class="block6-title" [innerHTML]="contents.block6Title"></div>
        <div class="block6-flex-container">
            <div class="block6-flex-item" [innerHTML]="contents.block6Row1"></div>
            <div class="block6-flex-item" [innerHTML]="contents.block6Row2"></div>
        </div>
        <div class="block6-flex-container mt-100">
            <div class="block6-flex-item" [innerHTML]="contents.block6Row3"></div>
            <div class="block6-flex-item" [innerHTML]="contents.block6Row4"></div>
        </div>
    </div>
</div>

<div class="casestudyrockwell-block10">
    <div class="container paddingless mt">
        <div class="block10-spacer2" [innerHTML]="contents.block10Image5"></div>
    </div>
    <div class="container">
        <div class="block10-flex-container">
            <div class="block10-flex-item">
                <div class="block10-title mb" [innerHTML]="contents.block10Title"></div>
                <div class="block10-text mb2" [innerHTML]="contents.block10TitleSmall"></div>
            </div>
            <div class="block10-flex-item"></div>
        </div>
        <div class="block10-flex-container">
            <div class="block10-flex-item">
                <div class="block10-pill block10-no-fillter" [innerHTML]="contents.block10Image1"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle1"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text1"></div>
            </div>
            <div class="block10-flex-item">
                <div class="block10-pill block10-no-fillter" [innerHTML]="contents.block10Image2"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle2"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text2"></div>
            </div>
        </div>
        <div class="block10-flex-container block10-flex-2nd">
            <div class="block10-flex-item">
                <div class="block10-pill block10-no-fillter" [innerHTML]="contents.block10Image3"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle3"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text3"></div>
            </div>
            <div class="block10-flex-item">
                <div class="block10-pill block10-no-fillter" [innerHTML]="contents.block10Image4"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle4"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text4"></div>
            </div>
        </div>
        <div class="block10-flex-container block10-flex-2nd">
            <div class="block10-flex-item">
                <div class="block10-pill block10-no-fillter" [innerHTML]="contents.block10Image6"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle5"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text5"></div>
            </div>
            <div class="block10-flex-item">
                <div class="block10-pill block10-no-fillter" [innerHTML]="contents.block10Image7"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle6"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text6"></div>
            </div>
        </div>
    </div>

    <div class="container paddingless mt">
        <div class="block10-flex-container2">
            <div class="block10-flex-item" [innerHTML]="contents.block10Image8"></div>
            <div class="block10-flex-item2" [innerHTML]="contents.block10Image9"></div>
        </div>
    </div>

    <div class="container mt">
        <div class="block10-flex-container">
            <div class="block10-flex-item">
                <div class="block10-title mb" [innerHTML]="contents.block10Title2"></div>
                <div class="block10-text mb2" [innerHTML]="contents.block10TitleSmall2"></div>
            </div>
            <div class="block10-flex-item"></div>
        </div>
        <div class="block10-flex-container">
            <div class="block10-flex-item">
                <div class="block10-pill" [innerHTML]="contents.block10Image10"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle7"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text7"></div>
            </div>
            <div class="block10-flex-item">
                <div class="block10-pill" [innerHTML]="contents.block10Image10"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle8"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text8"></div>
            </div>
        </div>
        <div class="block10-flex-container block10-flex-2nd">
            <div class="block10-flex-item">
                <div class="block10-pill" [innerHTML]="contents.block10Image10"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle9"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text9"></div>
            </div>
            <div class="block10-flex-item">
                <div class="block10-pill" [innerHTML]="contents.block10Image10"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle10"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text10"></div>
            </div>
        </div>
        <div class="block10-flex-container block10-flex-2nd">
            <div class="block10-flex-item">
                <div class="block10-pill" [innerHTML]="contents.block10Image10"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle11"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text11"></div>
            </div>
            <div class="block10-flex-item">
                <div class="block10-pill" [innerHTML]="contents.block10Image10"></div>
                <div class="block10-subtitle" [innerHTML]="contents.block10SubTitle12"></div>
                <div class="block10-text" [innerHTML]="contents.block10Text12"></div>
            </div>
        </div>
    </div>
</div>

<div class="casestudyrockwell-block7">
    <div class="container">
        <div class="block7-flex-container">
            <div class="block7-illustration"></div>
            <div class="block7-flex-item1" [innerHTML]="contents.block7Image1"></div>
            <div class="block7-flex-item2" [innerHTML]="contents.block7Image2"></div>
        </div>
    </div>
</div>

<div class="casestudyrockwell-block12">
    <div class="container">
        <div class="block12-container">
            <div class="block12-title3" [innerHTML]="contents.block12Title1"></div>
            <div class="block12-text" [innerHTML]="contents.block12Text1"></div>
        </div>
    </div>
</div>

<div class="casestudyrockwell-block13">
    <div class="container">
        <div class="block13-container">
            <div class="block13-text1" [innerHTML]="contents.block13Text"></div>
            <a class="block13-flex-container bottom-margine" [routerLink]="['/casestudyicon']">
                <div class="block13-flex-item1">
                    <div class="block13-title" [innerHTML]="contents.block13Title1"></div>
                    <div class="block13-text" [innerHTML]="contents.block13Text1"></div>
                    <div class="block13-pills-container">
                        <div class="pill" *ngFor="let item of contents.block13Pills">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="block13-flex-item2" [innerHTML]="contents.block13Image"></div>
            </a>
        </div>
    </div>
</div>

<div class="casestudyrockwell-block13">
    <div class="container">
        <div class="block13-illustration"></div>
        <div class="block13-container">
            <a class="block13-flex-container" [routerLink]="['/casestudyborgo']">
                <div class="block13-flex-item1">
                    <div class="block13-title" [innerHTML]="contents.block13Title2"></div>
                    <div class="block13-text" [innerHTML]="contents.block13Text2"></div>
                    <div class="block13-pills-container">
                        <div class="pill" *ngFor="let item of contents.block13Pills2">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="block13-flex-item2" [innerHTML]="contents.block13Image2"></div>
            </a>
        </div>
    </div>
</div>