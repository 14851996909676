import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-casestudyborgo',
  templateUrl: './casestudyborgo.component.html',
  styleUrls: ['./casestudyborgo.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CasestudyborgoComponent implements OnInit {

  public contents = environment.contents.casestudyborgo;

  private title = 'Selected Green - Borgo';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
