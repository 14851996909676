import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-schedulecall',
  templateUrl: './schedulecall.component.html',
  styleUrls: ['./schedulecall.component.scss']
})
export class SchedulecallComponent implements OnInit {
  private title = 'Selected Green - Schedule a call';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }
}
