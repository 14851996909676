import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from './../../environments/environment';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-casestudyicon',
  templateUrl: './casestudyicon.component.html',
  styleUrls: ['./casestudyicon.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class CasestudyiconComponent implements OnInit {

  public contents = environment.contents.casestudyicon;

  private title = 'Selected Green - Icon Accounting';

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
